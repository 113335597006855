import React from "react"
import { graphql } from "gatsby"
import Header from "../components/Header"
import Text from "../components/Text"
import { Helmet } from "react-helmet"
import Footer from "../components/Footer"



const Homepage = ({ data }) => {

  // console.log(data);

  var node = data.drupal.pageNode;
  // var paragraphs = {};

  
  

  // console.log(paragraphs);
  return (
      <div className={ node.language.id === "en" ? "EN" :"UR"}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{node.entityLabel}</title>
          <link rel="canonical" href="https://processton.com" />
          <meta http-equiv="Cache-control" content="public, max-age=0, must-revalidate"></meta>
        </Helmet>
        <Header lang={ node.language.id === "en" ? "EN" :"UR"} />
        <div className="container mx-auto">
          <div className="content px-24 py-12 text-brand">
            <div className="narrow">
              <Text text={ node.body.value} />
            </div>
          </div>
        </div>
        
        <Footer lang={ node.language.id === "en" ? "EN" :"UR"} />
      </div>
  )
}

export const query = graphql`
  query($id : String!, $language: DRUPAL_LanguageId!){
    drupal {
      pageNode: nodeById(id: $id, language: $language) {
        entityId
        entityLabel
        entityType
        entityUrl {
          path
        }
        
        language: entityLanguage {
          id
        }
        nid
        title
        sticky
        ... on DRUPAL_NodePage {
          nid
          vid
          uuid
          title
          status
          changed
          created
          entityBundle
          entityLanguage {
            id
          }
          entityLabel
          entityId
          body {
            format
            value
          }
        }
      }
    }
  }
`

export default Homepage
